.missing-skill-active {
    background-color: rgb(236, 46, 46);
    color: white;
    padding: 12px 16px;
    border: 1px solid rgb(236, 46, 46);
    border-radius: 4px;
    margin: 4px 6px 4px 0;
    outline: none;
    cursor: pointer;
}
  
.missing-skill {
    background-color: white;
    padding: 12px 16px;
    border: 1px solid rgb(236, 46, 46);
    border-radius: 4px;
    color: rgb(236, 46, 46);
    margin: 4px 6px 4px 0;
    outline: none;
    cursor: pointer;
}
  
.missing-skill:hover {
    background-color: rgb(236, 46, 46);
    color: whitesmoke;
}
